import React from 'react';
import { graphql } from 'gatsby';

// Components
import SeoDetails from '../components/common/seoDetails';
import HeroImage from '../components/common/sections/heroImage';
import JustText from '../components/common/sections/justText';

const PrivacyPage = ({ data }) => {
  const { seoContent, contentSections } = data.contentfulPages;

  return (
    <>
      <SeoDetails seoContent={seoContent} />
      <HeroImage seoContent={seoContent} />
      <JustText contentSection={contentSections[0]} />
    </>
  );
};

export const query = graphql`
  query {
    contentfulPages(id: { eq: "71139e33-8d42-5f51-aa66-69a800f023ca" }) {
      id
      title
      seoContent {
        pageTitle
        menuTitle
        slug
        description {
          description
        }
        featuredImage {
          title
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      contentSections {
        text {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;

export default PrivacyPage;
